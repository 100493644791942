import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadStripe } from '@stripe/stripe-js'
import SellerLayout from '../../../components/Layout/SellerLayout/Index'
import BreadCrumb from '../../../components/BreadCrumb/Index'
import Panel from '../../../components/Panel/Index'
import { RootState } from '../../../store'
import { createStripeCheckoutSession } from '../../../store/stripe/stripeReducer'

// Load Stripe
const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY)

const CreditPurchase = () => {
  const { user } = useSelector((state: RootState) => state.auth)
  const { isLoading } = useSelector((state: RootState) => state.stripe)
  const dispatch = useDispatch()
  const [credits, setCredits] = useState(10)
  const [isCustom, setIsCustom] = useState(false)
  const [customCredits, setCustomCredits] = useState('')
  const [creditCost, setCreditCost] = useState(1.5)
  const [setNewBalance] = useState<number>(user.credits)
  const creditRate = 0.15

  const creditOptions = [
    { credits: 10, price: 1.5 },
    { credits: 20, price: 3.0 },
    { credits: 40, price: 6.0 },
    { credits: 60, price: 9.0 },
    { credits: 80, price: 12.0 },
    { credits: 100, price: 15.0 },
    { credits: 150, price: 22.5 },
    { credits: 200, price: 30.0 },
    { credits: 250, price: 37.5 },
    { credits: 300, price: 45.0 },
    { credits: 'Custom', price: 0 },
  ]

  const handleCreditChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    if (value === 'Custom') {
      setIsCustom(true)
      setCustomCredits('')
      setCreditCost(0)
    } else {
      setIsCustom(false)
      const selectedCredits = creditOptions.find(
        (option) => option.credits === parseInt(value, 10)
      )
      if (selectedCredits) {
        setCredits(selectedCredits.credits)
        setCreditCost(selectedCredits.price)
        setNewBalance(user.credits + selectedCredits.credits)
      }
    }
  }

  const handleCustomCreditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const customValue = e.target.value
    if (customValue && parseInt(customValue, 10) > 0) {
      setCustomCredits(customValue)
      const customCost = (parseInt(customValue, 10) * creditRate).toFixed(2)
      setCreditCost(customCost)
      setNewBalance(user.credits + parseInt(customValue, 10))
    } else {
      setCustomCredits('')
      setCreditCost(0)
    }
  }

  const handlePurchase = async () => {
    const stripe = await stripePromise
    const finalCredits = isCustom ? customCredits : credits

    // Dispatch the action to create a Stripe checkout session
    const resultAction = dispatch(
      createStripeCheckoutSession({
        credits: finalCredits.toString(),
        customCredits: isCustom ? parseInt(customCredits, 10) : undefined,
      })
    )

    if (createStripeCheckoutSession.fulfilled.match(resultAction)) {
      const sessionId = resultAction.payload.sessionId

      // Redirect to Stripe Checkout using the sessionId
      const { error } = await stripe.redirectToCheckout({
        sessionId,
      })

      if (error) {
        console.error('Stripe Checkout Error:', error)
        alert('There was an error with the payment. Please try again.')
      }
    }
  }

  return (
    <SellerLayout>
      <BreadCrumb title="Buy Credits" url="/seller/dashboard" />
      <Panel
        title="Credit Purchase"
        description="Select the amount of credits you want to buy and complete the payment."
      >
        <div className="px-4 sm:px-0">
          <h3 className="text-base font-semibold leading-7 dark:text-white">
            Credits Information
          </h3>
          <p className="mt-1 max-w-2xl text-sm leading-6 dark:text-gray-100">
            Details about your purchase.
          </p>
        </div>
        <div className="mt-6 border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            {/* Display User's Available Credits */}
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 dark:text-white">
                Current Credits
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {user.credits}
              </dd>
            </div>

            {/* Select Credit Amount */}
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 dark:text-white">
                Select Credits
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <select
                  id="creditSelect"
                  value={isCustom ? 'Custom' : credits}
                  onChange={handleCreditChange}
                  className="w-full dark:bg-black dark:text-white p-2 bg-gray-200 border border-gray-300 rounded focus:outline-none"
                >
                  {creditOptions.map((option) => (
                    <option key={option.credits} value={option.credits}>
                      {option.credits === 'Custom'
                        ? 'Custom Amount'
                        : `${option.credits} for $${option.price.toFixed(2)}`}
                    </option>
                  ))}
                </select>
              </dd>
            </div>

            {/* Custom Credit Input (only show if custom is selected) */}
            {isCustom && (
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 dark:text-white">
                  Enter Custom Credits
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <input
                    type="number"
                    value={customCredits}
                    onChange={handleCustomCreditChange}
                    placeholder="Enter custom credit amount"
                    className="w-full dark:bg-black dark:text-white p-2 bg-gray-200 border border-gray-300 rounded focus:outline-none"
                    min="1"
                  />
                </dd>
              </div>
            )}

            {/* Display Cost */}
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 dark:text-white">
                Total Cost
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                ${creditCost} + Tax
              </dd>
            </div>

            {/* Purchase Button */}
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 dark:text-white">
                Action
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <button
                  onClick={handlePurchase}
                  className={`bg-purple-500 text-white font-semibold py-2 px-4 rounded hover:bg-purple-600 ${
                    isLoading ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  disabled={isLoading}
                >
                  {isLoading ? 'Processing...' : 'Buy Credits'}
                </button>
              </dd>
            </div>
          </dl>
        </div>
      </Panel>
    </SellerLayout>
  )
}

export default CreditPurchase
