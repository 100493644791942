import { useEffect } from 'react'
import SellerLayout from '../../../../components/Layout/SellerLayout/Index'
import { useDispatch } from 'react-redux'
import { getSessionStatus } from '../../../../store/stripe/stripeReducer'
import { getMe } from '../../../../store/auth/authReducer'

const CheckoutSuccess = () => {
  const dispatch = useDispatch()
  const queryParams = new URLSearchParams(window.location.search)
  const sessionId = queryParams.get('session_id')

  useEffect(() => {
    const fetchSession = async () => {
      if (sessionId) {
        try {
          const response = dispatch(getSessionStatus(sessionId))
          await dispatch(getMe())
          console.log('Session status response:', response)
        } catch (error) {
          console.error('Error fetching session status:', error)
        }
      } else {
        console.error('No session ID found in query parameters.')
      }
    }

    fetchSession()
  }, [sessionId, dispatch]) // Add sessionId as a dependency

  return (
    <SellerLayout>
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-center">
          <div className="w-24 h-24 bg-green-500 rounded-full flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-12 w-12 text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
          <h1 className="text-2xl font-semibold text-gray-700 dark:text-white py-2">
            Payment Successful
          </h1>
          <p className="text-gray-500 dark:text-gray-300">
            Your payment was successful
          </p>
        </div>
      </div>
    </SellerLayout>
  )
}

export default CheckoutSuccess
