import { Route, Routes } from 'react-router-dom'
import PrivateRoute from '../../../routing/PrivateRoute'
import SellerHome from '../Home/Index'
import Stripe from '../../Stripe/Index'
import AccountStatus from '../../Stripe/AccountStatus/Index'
import SellerProjects from '../Projects/Index'
import Tasks from '../Tasks/Index'
import CreateTask from '../Tasks/CreateTask/Index'
import { Fragment } from 'react'
import Onboarding from '../Onboarding/Index'
import SellerRedirect from '../../../routing/SellerRedirect'
import CreateProposal from '../Proposals/CreateProposal/Index'
import LeadsSellerPage from '../Leads/Index'
import SellerMessages from '../Messages/Index'
import NotFound from '../../../components/Errors/NotFound/Index'
import CreditPurchase from '../Credits/Index'
import CreditSuccess from '../Credits/Success/Index'

const SellerRoutes = () => {
  return (
    <Fragment>
      <Routes>
        {/* Seller Routes */}
        <Route
          path="/messages"
          element={
            <SellerRedirect>
              <PrivateRoute>
                <SellerMessages />
              </PrivateRoute>
            </SellerRedirect>
          }
        />
        <Route
          path="/stripe/connect"
          element={
            <PrivateRoute>
              <Stripe />
            </PrivateRoute>
          }
        />

        <Route
          path="/credits/success"
          element={
            <PrivateRoute>
              <CreditSuccess />
            </PrivateRoute>
          }
        />

        <Route
          path="/credits/cancel"
          element={
            <PrivateRoute>
              <h1>Cancel</h1>
            </PrivateRoute>
          }
        />

        <Route
          path="/stripe/credits"
          element={
            <PrivateRoute>
              <CreditPurchase />
            </PrivateRoute>
          }
        />

        <Route
          path="/onboarding"
          element={
            <SellerRedirect>
              <PrivateRoute>
                <Onboarding />
              </PrivateRoute>
            </SellerRedirect>
          }
        />

        <Route
          path="/stripe/callback"
          element={
            <PrivateRoute>
              <AccountStatus />
            </PrivateRoute>
          }
        />

        {/* Seller Project Routes */}
        <Route
          path="/projects"
          element={
            <SellerRedirect>
              <PrivateRoute>
                <SellerProjects />
              </PrivateRoute>
            </SellerRedirect>
          }
        />

        <Route
          path="/projects/:id/proposals/apply"
          element={
            <SellerRedirect>
              <PrivateRoute>
                <CreateProposal />
              </PrivateRoute>
            </SellerRedirect>
          }
        />

        {/* Seller Task Routes */}
        <Route
          path="/tasks"
          element={
            <SellerRedirect>
              <PrivateRoute>
                <Tasks />
              </PrivateRoute>
            </SellerRedirect>
          }
        />

        <Route
          path="/tasks/new"
          element={
            <SellerRedirect>
              <PrivateRoute>
                <CreateTask />
              </PrivateRoute>
            </SellerRedirect>
          }
        />
        {/* Seller Routes */}

        <Route
          path="/leads"
          element={
            <SellerRedirect>
              <PrivateRoute>
                <LeadsSellerPage />
              </PrivateRoute>
            </SellerRedirect>
          }
        />

        <Route
          path="/:username"
          element={
            <SellerRedirect>
              <PrivateRoute>
                <SellerHome />
              </PrivateRoute>
            </SellerRedirect>
          }
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Fragment>
  )
}
export default SellerRoutes
